export default [
    {
        name: "Em Tuấn Anh Khoá 2005",
        content: "Sau khi học tại Lớp Cô Hằng Nga, điều em thích nhất chính là học được thói quen tự học tiếng Anh",
        designation: "Thủ khoa Khối A1",
        avatar: "assets/img/mshangnga/man.png",
    },
    {
        name: "Em Quốc Huy Khoá 2004",
        content: "Em thíchhh nhất đó chính là trình độ giao tiếp tiếng Anh cao lên hẳn một bậc",
        designation: "7.5 IELTS",
        avatar: "assets/img/mshangnga/man2.png",
    },
];

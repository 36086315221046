export default {
    phone: '0988 999 387',
    fanpage: 'https://www.facebook.com/MsHangNgaEDU',
    facebook: 'https://www.facebook.com/cogaivinhphuc',
    address: 'Số nhà 16 Ngõ 2 Phương Mai - Đống Đa - Hà Nội',
    brandName: 'Ms Hằng Nga Education',
    slogan: 'Nơi gửi gắm niềm tin của cha mẹ',
    tiktok: '',
    map: 'https://goo.gl/maps/qsvmxkcAh4y522Au9',
    teacherName: 'Nguyễn Hằng Nga',
    
}